import React from 'react';
import DreherLogo from '../../assets/Schriftzug-dm.png';
import { withNamespaces } from 'react-i18next';

function Footer({ language, t }) {
	return (
		<footer className="margin-footer">
			<div className="footer-section">
				<div className="item item-1">
					<a href="/imprint">{t('imprint')}</a>
					<a>,&nbsp;</a>
					<a href="/privacy">{t('data & privacy')}</a>
				</div>
				<div className="item item-2">
					<p>©2021 by Dominik Wagner</p>
				</div>
				<div className="item item-3">
					{t('created by')}
					<a href="https://www.dreher-media.de/" target="_blank" rel="noopener noreferrer">
						<img src={DreherLogo} alt="logo dreher.media" />
					</a>
				</div>
			</div>
		</footer>
	);
}

export default withNamespaces()(Footer);
