import React from 'react';

function Imprint() {
	return (
        <div className="imprint-section">
<font   ><font size="5"><h2>Impressum</h2></font></font>
<p >
<font   > </font>
</p>
<p  >
<font   ><font size="3"   >Angaben
gem&auml;&szlig; &sect;5 TMG:</font></font></p>
<p  >
<font   ><font size="3"   >www.dominikemanuelwagner.com
ist ein Angebot von</font></font></p>
<p  >
<font   ><font size="3"   >Dominik
Wagner</font></font></p>
<p  >
<br/>
<br/>

</p>
<p>Fotos: Julia Wesely, Stephan Polzer, Rania Moslam</p>
<p  >
<br/>
<br/>

</p>
<p  >
<font   ><font size="3"   >E-Mail-Adresse:
bass@dominikemanuelwagner.com</font></font></p>
<p  >
<br/>
<br/>

</p>
<p  >
<font   ><font size="3"   >Webdesign
&amp; Umsetzung:</font></font></p>
<p  >
<a href="http://www.dreher-media.de/"><font color="#954f72"><font size="3"   ><u>www.dreher-media.de</u></font></font></a></p>
<p  >
<br/>
<br/>

</p>
<p  >
<br/>
<br/>

</p>
<p align="justify"   >
<font color="#333333"><font size="3"   >Haftung
f&uuml;r Inhalte</font></font></p>
<p align="justify"   >
<font color="#333333"><font size="3"   >Als
Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r
eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
gespeicherte fremde Informationen zu &uuml;berwachen oder nach
Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
hinweisen.</font></font></p>
<p align="justify"   >
<font color="#333333"><font size="3"   >Verpflichtungen
zur Entfernung oder Sperrung der Nutzung von Informationen nach den
allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine
diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der
Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei
Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
Inhalte umgehend entfernen.</font></font></p>
<p class="western" ><a name="_66s4qjf77xl4"></a>
<font color="#000000"><font size="3"   ><b>Haftung
f&uuml;r Links</b></font></font></p>
<p>
<font size="3"   >Unser Angebot enth&auml;lt
Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden
Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte
der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft.
Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
derartige Links umgehend entfernen.</font></p>
<p  >
 
</p>
<p align="justify"   >
<font color="#333333"><font size="3"   >Urheberrecht</font></font></p>
<p align="justify"   >
<font color="#333333"><font size="3"   >Die
durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung,
Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb
der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen
Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
Kopien dieser Seite sind nur f&uuml;r den privaten, nicht
kommerziellen Gebrauch gestattet.</font></font></p>
<p align="justify"   >
<font color="#333333"><font size="3"   >Soweit
die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
werden die Urheberrechte Dritter beachtet. Insbesondere werden
Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
werden wir derartige Inhalte umgehend entfernen.</font></font></p>
<p  >
 
</p>
<p>
<font   ><font size="3"   >Die
Europ&auml;ische Kommission stellt eine Plattform f&uuml;r die
au&szlig;ergerichtliche Online-Streitbeilegung (OS-Plattform) bereit,
die unter<a href="http://www.ec.europa.eu/consumers/odr">
</a></font></font><a href="http://www.ec.europa.eu/consumers/odr"><font color="#002161"><font size="3"   ><u>www.ec.europa.eu/consumers/odr</u></font></font></a><font   ><font size="3"   >
aufrufbar ist. Wir sind nicht bereit und nicht verpflichtet, an
dieser Streitbeilegung teilzunehmen. </font></font>
</p>
<p><br/>

</p>
</div>

);
}
export default Imprint;
