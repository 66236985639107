import React from 'react';
import { withNamespaces } from 'react-i18next';

function Contact({ t }) {
	return (
		<div className="contact-grid">
			<div className="item">
				<h2 className="normal-headline">{t('contact')}</h2>
				<div className="contact-item">
					<div className="contact-item">
						<div className="hover-box">
							<a href="mailto:bass@dominikemanuelwagner.com">
								<svg
									width="38"
									height="38"
									viewBox="0 0 38 38"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M34.6602 4.52753H3.33984C1.50055 4.52753 0 6.02645 0 7.86737V30.133C0 31.9677 1.49388 33.4728 3.33984 33.4728H34.6602C36.4948 33.4728 38 31.979 38 30.133V7.86737C38 6.03268 36.5061 4.52753 34.6602 4.52753ZM34.199 6.75409L19.0708 21.8823L3.8118 6.75409H34.199ZM2.22656 29.6719V8.3178L12.9496 18.9489L2.22656 29.6719ZM3.80096 31.2463L14.5308 20.5165L18.2905 24.2439C18.7257 24.6754 19.428 24.674 19.8615 24.2405L23.5273 20.5746L34.199 31.2463H3.80096ZM35.7734 29.6719L25.1017 19.0002L35.7734 8.32842V29.6719Z"
										fill="white"
									/>
								</svg>
							</a>

							<div className="center-link">
								<a href="mailto:bass@dominikemanuelwagner.com">bass@dominikemanuelwagner.com</a>
							</div>
						</div>
					</div>

					<div className="contact-item">
						<div className="hover-box">
							<a
								href="https://de-de.facebook.com/dominikemanuelwagner/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<svg
									width="30"
									height="30"
									viewBox="0 0 30 30"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M4.39453 30H14.1797V19.3359H10.6641V15.8203H14.1797V11.4258C14.1797 9.00261 16.151 7.03125 18.5742 7.03125H22.9687V10.5469H19.4531C18.4838 10.5469 17.6953 11.3354 17.6953 12.3047V15.8203H22.8101L22.2242 19.3359H17.6953V30H25.6055C28.0286 30 30 28.0286 30 25.6055V4.39453C30 1.97136 28.0286 0 25.6055 0H4.39453C1.97136 0 0 1.97136 0 4.39453V25.6055C0 28.0286 1.97136 30 4.39453 30ZM1.75781 4.39453C1.75781 2.94067 2.94067 1.75781 4.39453 1.75781H25.6055C27.0593 1.75781 28.2422 2.94067 28.2422 4.39453V25.6055C28.2422 27.0593 27.0593 28.2422 25.6055 28.2422H19.4531V21.0937H23.7133L24.8852 14.0625H19.4531V12.3047H24.7266V5.27344H18.5742C15.1817 5.27344 12.4219 8.03329 12.4219 11.4258V14.0625H8.90625V21.0937H12.4219V28.2422H4.39453C2.94067 28.2422 1.75781 27.0593 1.75781 25.6055V4.39453Z"
										fill="white"
									/>
								</svg>
							</a>

							<div className="center-link">
								<a
									href="https://de-de.facebook.com/dominikemanuelwagner/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Dominik Wagner
								</a>
							</div>
						</div>
					</div>

					<div className="contact-item">
						<div className="hover-box">
							<a href="https://instagram.com/dominik_bass" target="_blank" rel="noopener noreferrer">
								<svg
									width="38"
									height="38"
									viewBox="0 0 38 38"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M26.9617 0H11.0384C4.9518 0 0 4.9518 0 11.0384V26.9616C0 33.0482 4.9518 38 11.0384 38H26.9616C33.0482 38 38 33.0482 38 26.9617V11.0384C38 4.9518 33.0482 0 26.9617 0ZM35.0312 26.9616C35.0312 31.4112 31.4112 35.0312 26.9617 35.0312H11.0384C6.58877 35.0312 2.96875 31.4112 2.96875 26.9617V11.0384C2.96875 6.58877 6.58877 2.96875 11.0384 2.96875H26.9616C31.4112 2.96875 35.0312 6.58877 35.0312 11.0384V26.9616Z"
										fill="white"
									/>
									<path
										d="M19 8.75781C13.3524 8.75781 8.75781 13.3524 8.75781 19C8.75781 24.6476 13.3524 29.2422 19 29.2422C24.6476 29.2422 29.2422 24.6476 29.2422 19C29.2422 13.3524 24.6476 8.75781 19 8.75781ZM19 26.2734C14.9894 26.2734 11.7266 23.0106 11.7266 19C11.7266 14.9894 14.9894 11.7266 19 11.7266C23.0106 11.7266 26.2734 14.9894 26.2734 19C26.2734 23.0106 23.0106 26.2734 19 26.2734Z"
										fill="white"
									/>
									<path
										d="M29.3906 10.0938C30.2104 10.0938 30.875 9.42917 30.875 8.60938C30.875 7.78958 30.2104 7.125 29.3906 7.125C28.5708 7.125 27.9062 7.78958 27.9062 8.60938C27.9062 9.42917 28.5708 10.0938 29.3906 10.0938Z"
										fill="white"
									/>
								</svg>
							</a>

							<div className="center-link">
								<a href="https://instagram.com/dominik_bass" target="_blank" rel="noopener noreferrer">
									dominik_bass
								</a>
							</div>
						</div>
					</div>
					<div className="managment">
						<h3>Managment</h3>
						<p>
						<b>Vienna Arts Management</b><br/>
						Stuckgasse 1/15 | 1070 Wien | Österreich<br/>
						Managing Director<br/>
						Mag. Florian Petermann, BA<br/>
						</p>
						<a href="mailto:florian@viennaartsmanagement.com">florian@viennaartsmanagement.com</a><br/>
						<a className="tel-link" href="tel:+43 1 923 71 63">+43 1 923 71 63</a>
						<br/>
						<br/>
						<br/>
						<p>
						<b>Berlin Classics</b><br/>
						Edel Germany GmbH<br/>
						Rosa-Luxemburg-Straße 14 | 10178 Berlin | Deutschland<br/>
						Marcus Heinicke<br/>
						</p>
						<a href="mailto:marcus.heinicke@edel.com">marcus.heinicke@edel.com</a>
						<br/>
						<br/>
						<br/>
						<div className="play-section">
						<p>proudly plays</p>
						<a className="img-link" href="https://www.thomastik-infeld.com/en/home" target="_blank" rel="noopener noreferrer">
						<img src="./img/thomastik.svg" alt="Thomastik Logo" />
						</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withNamespaces()(Contact);
